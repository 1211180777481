import React from "react";
import { Link } from "gatsby";
import { Navbar } from "./Navbar";
import Logo from "../assets/MU-Dynamics-Logo-v3.svg";
import headerCss from "./Header.module.css";
import { SkipNav } from "./helpers";


const Header = () => (
    <header className={ headerCss.wrapper }>
        <div className={ headerCss.container }>
            <SkipNav/>
            <div className={ headerCss.logo }>
                <div className={ "" }>
                    <Link className={ headerCss.link } to="/" aria-label="home">
                        <img src={ Logo } alt="MU Dynamics" height="36px"/>
                    </Link>
                </div>
            </div>
            <Navbar/>
        </div>
    </header>
);

export {
    Header
};