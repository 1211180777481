import React from "react";
import { Header } from "./Header";
import Drift from "react-driftjs";
import "./layout.css";

class Template extends React.Component
{
    render()
    {
        const { children } = this.props;

        return (
            <main style={ {
                background: "#ffffff",
                color: "#232323"
            } }>
                <Drift appId={ "4ktbamxvip9b" }/>
                <Header location={ this.props.location }/>
                { children }
            </main>
        );
    }
}

export default Template;
