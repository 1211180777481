import React from "react";
import footerCss from "./Footer.module.css";

const Footer = () => (
    <div className={ footerCss.container }>
        <div className={ footerCss.copyright }>
            <span>© 2018 M Dev, Ltd.</span>
        </div>
    </div>
);

export {
    Footer
};