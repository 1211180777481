import React from 'react'
import * as gatsby from "gatsby";

import buttonCss from './Button.module.css'

const lightClasses = `${buttonCss.base} ${buttonCss.light}`;
const navClasses = `${buttonCss.base} ${buttonCss.nav}`;

const Button = (props) => (
    <a href={ props.href } className={ buttonCss.base }>{props.children}</a>
);

const Link = (props) => (
    <gatsby.Link to={ props.href } className={ buttonCss.base }>{props.children}</gatsby.Link>
);

const Toggle = (props) => (
    <button onClick={ props.onClick } className={ buttonCss.base}>{props.children}</button>
);

const ButtonLight = (props) => (
    <a href={ props.href } className={ lightClasses }>{props.children}</a>
);

const LinkLight = (props) => (
    <gatsby.Link to={ props.href } className={ lightClasses }>{props.children}</gatsby.Link>
);

const ButtonNav = (props) => (
    <a href={ props.href } className={ navClasses }>{props.children}</a>
);

const LinkNav = (props) => (
    <gatsby.Link to={ props.href } className={ navClasses }>{props.children}</gatsby.Link>
);

const ToggleNav = (props) => (
    <button onClick={ props.onClick } className={ navClasses}>{props.children}</button>
);

export {
    Button,
    Link,
    Toggle,
    ButtonLight,
    LinkLight,
    ButtonNav,
    LinkNav,
    ToggleNav,
}