import React from "react";
import navbarCss from "./Navbar.module.css";
import { LinkNav, ToggleNav } from "./Button";
import { openChat } from "./helpers";

const Navbar = () => (
    <div className={ navbarCss.nav } role="navigation">
        <ul className={ navbarCss.list }>
            <li className={ navbarCss.listItem }>
                <LinkNav href={ "/mdev" }>M DEV</LinkNav>
            </li>
            <li className={ navbarCss.listItem }>
                <LinkNav href={ "/udev" }>U DEV</LinkNav>
            </li>
            <li className={ navbarCss.listItem }>
                <ToggleNav onClick={openChat}>Contact</ToggleNav>
            </li>
        </ul>
    </div>
);

export {
    Navbar
};