import Typography from "typography";

const typography = new Typography({
    baseFontSize: "16px",
    scaleRatio: 2,
    googleFonts: [
        {
            name: "Source Sans Pro",
            styles: [
                "400"
            ]
        },
        {
            name: "Source Code Pro",
            styles: [
                "400"
            ]
        }
    ],
    headerFontFamily: ["Source Sans Pro", "sans-serif"],
    bodyFontFamily: ["Source Code Pro", "monospace"],
    headerWeight: "normal",
    bodyWeight: "normal"
});

export default typography;
export const rhythm = typography.rhythm;
export const scale = typography.scale;
