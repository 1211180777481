import React from 'react'
import { Button } from "./Button";

import helpersCss from './helpers.module.css'

const SkipNav = () => (
    <div className={helpersCss.container}>
        <Button href={"#content"}>Skip to main content</Button>
    </div>
);

const MainContent = ({children}) => (
    <div id={"content"} tabIndex={-1}>
        {children}
    </div>
);

const openChat = () => {
    if (window.drift) {
        window.drift.api.openChat();
    }
};

export {
    MainContent,
    SkipNav,
    openChat,
}